import "./Home.css";
import bitlaab from "../../Assets/bitlaab-dark.svg";
import { Clock, SocialLinks} from "../../Components";

export const Home = () => {
  return (
    <div data-attr="home-page" data-page={true} >
      {/* Page Content */}
      <div className="content-wrapper width">
        <div data-attr="nemo" className="sm:hidden">
          <h1 className="cinzel">NEMOSTAR</h1>
        </div>

        {/* Clock */}
        <div className="clock-wrapper"><Clock /></div>

        {/* Info Wrapper */}
        <div className="info-wrapper">
          <div className="name-box">
            <h1 className="cinzel">NEYaz NafIZ</h1>
            <p>
              <span>
                Currently, I am working at <a href="https://bitlaab.com/" target="_blank" rel="noreferrer"><img src={bitlaab} alt="" className="w-[82px]" /></a>
              </span>
              
              <span>
                My areas of expertise are Software/Application Engineering, UI/UX Design, as well as Web Automation.
              </span>
            </p>
          </div>

          <div className="social-section"><SocialLinks /></div>
        </div>
      </div>
    </div>
  );
};
