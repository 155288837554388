import Tech from "./Tech";
import Lottie from "lottie-react";
import { Home } from "../Home/Home";
import { useState } from "react";
import home from "../../Assets/home.json";
import { Helmet } from "react-helmet-async";
import { Projects } from "./Projects/Projects";
import { useInView } from "react-intersection-observer";
import { Brain, MyInfo, RandomFacts, AboutMe, Strategy } from "../../Components";
import LoadingBar from "react-top-loading-bar";

export const Portfolio = () => {
  const [startingAnimation, setStartingAnimation] = useState(true);
  const [pageState, setPageState] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.2 });

  const [progress, setProgress] = useState(100);

  const pageContentToggle = () => {
    setTimeout(() => {
      setPageState(true);
      setStartingAnimation(false);
    }, 1860);
  };

  return (
    <div
      data-attr="portfolio-page"
      data-page={pageState}
      onLoad={pageContentToggle()}
      data-barba="wrapper"
      data-barba-namespace="home"
      className="pb-24 lg:pb-16 pt-8 relative overflow-hidden"
    >
      {/* seo */}
      <Helmet>
        <title>Neyaz Nafiz | Software/Application Engineer</title>
        <meta
          name="description"
          content="Hey there! This is Neyaz Nafiz. My areas of expertise are Software & Web
          Application Development, UI/UX Design, as well as Web Automation."
        />
        <link rel="canonical" href="https://neyaznafiz.xyz/" />
      </Helmet>

      <LoadingBar
        color="#292524"
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      {/* Starting Animation */}
      <div
        id="starting-animation"
        data-starting-animation={startingAnimation}
        className="fixed top-0 h-screen w-screen bg-[var(--primary-color)] z-[99999] flex items-center justify-center"
      >
        <Lottie animationData={home} className="h-[120px]" />
      </div>

      <Home />

      {/* Page Content */}
      <div className="content-wrapper">
        <div className="md:mt-[48px] sm:mb-[128px] mb-10"><Brain /></div>

        <AboutMe />
        <Strategy/>

        <div className="width ref={ref} mx-auto sm:p-10 py-8 sm:pt-20">
          <div className="flex flex-col sm:flex-row gap-[24px] justify-center max-w-[90%] lg:max-w-[92%] lg:h-[300px] mx-auto">
            <a href="https://github.com/neyaznafiz" target="_blank" rel="noreferrer" className="relative z-[5]">
              <img
                src="https://github-profile-cards-nemo.vercel.app/api/cards/stats?username=neyaznafiz&theme=thenemo"
                alt="GitHub Contribution"
                className="mx-auto w-full lg:w-[100%] h-full"
              />
            </a>

            <a href="https://github.com/neyaznafiz" target="_blank" rel="noreferrer" className="relative z-[5]">
              <img
                src="https://github-profile-cards-nemo.vercel.app/api/cards/profile-details?username=neyaznafiz&theme=thenemo"
                alt="GitHub Contribution"
                className="mx-auto w-full lg:w-[100%] h-full"
              />
            </a>

            <a href="https://github.com/neyaznafiz" target="_blank" rel="noreferrer" className="relative z-[5]">
              <img
                src="https://github-profile-cards-nemo.vercel.app/api/cards/productive-time?username=neyaznafiz&theme=thenemo&utcOffset=8"
                alt="GitHub Contribution"
                className="mx-auto w-full lg:w-[100%] h-full"
              />
            </a>
          </div>

          <a href="https://github.com/neyaznafiz" target="_blank" rel="noreferrer" className="relative z-[5]">
            <img
              src="https://github-readme-activity-graph.vercel.app/graph?username=neyaznafiz&theme=react-dark&bg_color=dark&color=777777&line=daa523&point=ca8a04&hide_border=true"
              alt="GitHub Contribution"
              className="mx-auto w-full lg:w-[92%] h-full"
            />
          </a>
        </div>

        <div className="w-[100vw]"> <Projects /> </div>
        <div className="width w-fit mx-auto my-16 sm:my-20 p-4"> <Tech /> </div>
        <div className="w-[100vw] -my-10 md:-my-0 lg:-my-20"> <RandomFacts /> </div>
        <MyInfo />
      </div>
    </div>
  );
};
