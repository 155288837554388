import "./Blog.css";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet-async";
import author from "../../Assets/neyaz.jpg";
import book from "../../Assets/opening-book.json";
import developer from "../../Assets/developer.json";
import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";

export const Blog = () => {
  const [startingAnimation, setStartingAnimation] = useState(true);
  const [pageState, setPageState] = useState(false);

  const [posts, setPosts] = useState([]);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    fetch("blog.json")
      .then((res) => res.json())
      .then((data) => setPosts(data));
  }, []);

  const pageContentToggle = () => {
    setTimeout(() => {
      setPageState(true);
      setStartingAnimation(false);
    }, 1500);
  };

  return (
    <div
      className="min-h-screen"
      data-attr="posts-page"
      data-page={pageState}
      onLoad={pageContentToggle()}
      data-barba="wrapper"
      data-barba-namespace="neyaz-posts"
    >
      {/* seo */}
      <Helmet>
        <title>Neyaz Nafiz | Blog</title>
        <meta name="description" content="Read Neyaz's Posts. Neyaz strive to create a space where ideas flourish, and meaningful discussions thrive. From informative articles to thought-provoking insights" />
        <link rel="canonical" href="https://neyaznafiz.xyz/neyaz-posts" />
      </Helmet>

      <LoadingBar
        color="#292524"
        height={4}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      {/* Starting Animation */}
      <div
        id="starting-animation"
        data-starting-animation={startingAnimation}
        className="absolute top-0 h-screen w-screen bg-[var(--primary-color)] z-[9999] flex items-center justify-center"
      >
        <Lottie animationData={book} className="h-[248px]" />
      </div>

      {/* Page Content */}
      <div className="content-wrapper">
        <div className="width mx-auto p-2 pb-12 pt-[64px]">
          <div data-attr="nemo" className="w-full max-w-[1230px] mx-auto pt-5 sm:hidden">
            <h1 className="cinzel">ThENEMO</h1>
          </div>

          {/* text */}
          <div className="pt-5 sm:pt-10">
            <p className="flex flex-col gap-4 w-full max-w-[1230px] mx-auto ">
              <span>
                I am delighted to have you here, and I appreciate you taking the
                time to explore my corner. I extend to you my warmest greetings.
              </span>

              <span>
                I strive to create a space where ideas flourish, and meaningful
                discussions thrive. From informative articles to
                thought-provoking insights, I hope you will find something that
                resonates with you.
              </span>

              <span>
                Feel free to explore through my collection of thoughts and immerse
                yourself in the diverse topics I cover. Don't hesitate to share
                your thoughts and engage in conversations with me. Your
                perspective is valued, and I look forward to building a
                connection with you.
                <a href="https://www.linkedin.com/in/neyaznafiz/" target="_blank" rel="noreferrer"
                  className="ml-2 font-semibold hover:text-[var(--variant-light-gold)] transition-all duration-[250ms] relative z-[5]"
                >
                  Let's connect...
                </a>
              </span>

              <span>Thank you for being a part of my thoughts. Happy reading!</span>
              <span>Best regards, <br /> Neyaz Mobalik Nafiz</span>
            </p>
          </div>

          <div className="w-full max-w-[1230px] h-fit mx-auto">
            {/* <a href="https://buymeacoffee.com/neyaznafiz" target="_blank" className="mx-auto block w-fit mt-12 mb-4"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className="buymeacoffee-btn" /></a> */}
            
            <Lottie animationData={developer} className="h-[240px] w-fit mx-auto lg:mx-0 lg:ml-auto lg:-mt-14 lg:mb-20" />
          </div>

          {/* Blog Cards */}
          <div className="pb-20 w-full max-w-[1230px] mx-auto">

            <div className="flex gap-12 flex-wrap justify-center xl:justify-between">
              {posts.map((post) => (
                <a href={post.link} target="_blank" rel="noreferrer" key={post.id} className="post-card max-w-[348px] h-full transition-all duration-[250ms]">
                  <div className="py-4 text-box relative z-10">
                    <p className="tag absolute right-0 bottom-[124px] bg-[var(--secondary-color)] text-[var(--variant-light-gold)] px-2 py-[2px]">{post.tag}</p>
                    
                    <h1 className="font-semibold text-[18px] sm:text-[22px] mb-3 min-h-[72px]">{post.title}</h1>
                    <p data-attr="info" className="text-[14px] sm:text-[15px]">{post.info.slice(0, 110)}... <span className="read-more">Read More</span></p>

                    <p className="text-[12px] pt-4 date">{post.date}</p>

                    <img src={author} alt="" className="author w-[32px] h-[32px] rounded-full bottom-3 right-0 absolute transition-all duration-[250ms] object-cover"/>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
