import { FaDev, FaGithub, FaLinkedin, FaFacebook } from "react-icons/fa";
import { SiDailydotdev } from "react-icons/si";

export const SocialLinks = () => {
  return (
    <div>
      <div className="flex justify-center sm:justify-start gap-5 w-full sm:w-fit">
        <div className="flex sm:flex-col flex-row gap-5">
          {/* github */}
          <a
            href="https://github.com/neyaznafiz"
            target="_blank"
            rel="noreferrer"
            className="border border-primary max-w-40 max-h-40 w-fit h-fit p-2 sm:p-3 text-primary hover:text-secondary hover:bg-primary transition-all duration-500"
          >
            <FaGithub className="text-[40px] sm:text-[96px] border border-primary p-[8px] sm:p-[24px]" />
          </a>

          {/* Facebook */}
          <a
            href="https://app.daily.dev/squads/bitpress"
            target="_blank"
            rel="noreferrer"
            className="border border-primary max-w-40 max-h-40 w-fit h-fit p-2 sm:p-3 text-primary hover:text-secondary hover:bg-primary transition-all duration-500"
          >
            <SiDailydotdev className="text-[40px] sm:text-[96px] border border-primary p-[8px] sm:p-[24px]" />
          </a>
        </div>

        <div className="flex sm:flex-col flex-row gap-5">
          {/* linkdin */}
          <a
            href="https://www.linkedin.com/in/neyaznafiz/"
            target="_blank"
            rel="noreferrer"
            className="border border-primary max-w-40 max-h-40 w-fit h-fit p-2 sm:p-3 text-primary hover:text-secondary hover:bg-primary transition-all duration-500"
          >
            <FaLinkedin className="text-[40px] sm:text-[96px] border border-primary p-[8px] sm:p-[24px]" />
          </a>

          {/* devto */}
          <a
            href="https://dev.to/neyaznafiz"
            target="_blank"
            rel="noreferrer"
            className="border border-primary max-w-40 max-h-40 w-fit h-fit p-2 sm:p-3 text-primary hover:text-secondary hover:bg-primary transition-all duration-500"
          >
            <FaDev className="text-[40px] sm:text-[96px] border border-primary p-[8px] sm:p-[24px]" />
          </a>
        </div>

      </div>
    </div>
  );
};
